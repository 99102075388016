import TextInput from './text-input'
import PropTypes from 'prop-types'
import { Card, Col, Row } from 'reactstrap'
import { useEffect, useState } from 'react'

const propTypes = {
  index: PropTypes.number,
  place: PropTypes.object,
  handleEditPlaces: PropTypes.func,
  handleIsPlaceValid: PropTypes.func
}

const FileEditComponent = ({ index, place = {}, handleEditPlaces = () => {}, handleIsPlaceValid = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [longitude, setLongitude] = useState('')
  const [latitude, setLatitude] = useState('')
  const [url, setUrl] = useState('')
  const [isValid, setIsValid] = useState(false)
  useEffect(() => {
    setName(place.name)
    setDescription(place.description)
    setLongitude(place.longitude.toString())
    setLatitude(place.latitude.toString())
    setUrl(place.webSrc)
  }, [])

  useEffect(() => {
    if (name && description && latitude && longitude && url) {
      handleIsPlaceValid(true)
      setIsValid(true)
    } else {
      handleIsPlaceValid(false)
      setIsValid(false)
    }
    if (isNaN(parseFloat(latitude)) || isNaN(parseFloat(longitude))) {
      handleIsPlaceValid(false)
      setIsValid(false)
    }
    const data = {
      name,
      description,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      webSrc: url
    }
    handleEditPlaces(data, index)
  }, [name, description, longitude, latitude, url])

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleLongitudeChange = (e) => {
    setLongitude(e.target.value)
  }

  const handleLatitudeChange = (e) => {
    setLatitude(e.target.value)
  }

  const handleUrlChange = (e) => {
    setUrl(e.target.value)
  }

  return (
    <div key={index} className='mt-1 justify-content-center align-items-center'>
      <Col>
        <Card className='file-edit-component justify-content-center' style={isValid ? {} : { border: '1px solid red' }}>
          <Row>
            <Col md='2' className='ps-3'>
              {isValid && <img src='/media/geo-alt-fill.svg' alt='place icon' />}
              {!isValid && <img src='/media/exclamation-octagon.svg' alt='error icon' />}
            </Col>
            <Col md='8' className='pt-1'>
              <h5>{place.name}</h5>
            </Col>
            <Col md='2' className='ps-0'>
              {!isOpen &&
                <div className='float-end d-flex justify-content-center align-items-center img-button'>
                  <img src='/media/caret-left.svg' alt='left' onClick={() => setIsOpen(!isOpen)} />
                </div>}
              {isOpen &&
                <div className='float-end d-flex justify-content-center align-items-center img-button'>
                  <img src='/media/caret-down-fill.svg' alt='left' onClick={() => setIsOpen(!isOpen)} />
                </div>}
            </Col>
          </Row>
        </Card>
        {isOpen &&
          <Card>
            <TextInput icon='/media/geo-alt-fill.svg' value={name} type='text' label='place name' dataType='string' handleChange={handleNameChange} />
            <TextInput icon='/media/body-text.svg' value={description} type='textarea' label='place description' dataType='string' handleChange={handleDescriptionChange} />
            <TextInput icon='/media/at.svg' value={longitude} type='text' label='longitude' dataType='number' handleChange={handleLongitudeChange} />
            <TextInput icon='/media/at.svg' value={latitude} type='text' label='latitude' dataType='number' handleChange={handleLatitudeChange} />
            <TextInput icon='/media/link.svg' value={url} type='text' label='url' dataType='string' handleChange={handleUrlChange} />
          </Card>}
      </Col>
    </div>
  )
}

FileEditComponent.propTypes = propTypes

export default FileEditComponent
