import React, { Component } from 'react'
import Store from './utils/reducer'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import Earth from './Earth'
import Login from './login/login'
import { Provider } from 'react-redux'
import { requireAuthentication } from './utils/requireAuthentication'
import { requireWeakAuth } from './utils/requireWeakAuth'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <Provider store={Store}>
          <Switch>
            <Route exact path='/' component={requireWeakAuth(Login)} />
            <Route exact path='/earth' component={requireAuthentication(Earth)} />
          </Switch>
        </Provider>
      </BrowserRouter>
    )
  }
}

export default App
