import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastBody, ToastHeader, Collapse } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { post, get } from '../utils/httpAgent'
import TextInput from './text-input'
import PropTypes from 'prop-types'

const propTypes = {
  mode: PropTypes.string,
  modal: PropTypes.bool,
  id: PropTypes.string,
  toggle: PropTypes.func
}

const AddOnePlaceModal = ({ mode = 'create', modal = false, id = '', toggle = () => {} }) => {
  const [isToastOpen, setIsToastOpen] = useState(false)
  const [resultState, setResultState] = useState('success')
  const [message, setMessage] = useState('Place successfully created, click on the list to view it')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [longitude, setLongitude] = useState('')
  const [latitude, setLatitude] = useState('')
  const [url, setUrl] = useState('')
  const [isPlaceValid, setIsPlaceValid] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (mode === 'update' && modal) {
      get(`/1/account/places/placeid?id=${id}`).then((response) => {
        if (response.success) {
          const place = response.data.place
          setName(place.name)
          setDescription(place.description)
          setLongitude(place.longitude.toString())
          setLatitude(place.latitude.toString())
          setUrl(place.webSrc)
        }
      })
    } else {
      setName('')
      setDescription('')
      setLongitude('')
      setLatitude('')
      setUrl('')
    }
  }, [id, modal, mode])

  const handleSubmit = () => {
    const placeData = {
      id: id || null,
      name,
      description,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      url,
      priority: 0
    }
    if (mode === 'create') {
      post('/1/account/place/add', placeData).then(response => {
        if (response.success) {
          dispatch({ type: 'ADD_PLACE', place: response.data.place })
          setMessage('Place successfully created, click on the list to view it')
          setIsToastOpen(true)
          setResultState('success')
        }
      }).catch(err => {
        console.error(err)
        setMessage('Failed to create place')
        setIsToastOpen(true)
        setResultState('danger')
      })
    } else if (mode === 'update') {
      post('/1/account/place/update', placeData).then(response => {
        if (response.success) {
          dispatch({ type: 'UPDATE_PLACE', place: response.data.place })
          setMessage('Place successfully updated, click on the list to view it')
          setIsToastOpen(true)
          setResultState('success')
        }
      }).catch(err => {
        console.error(err)
        setMessage('Failed to update place')
        setIsToastOpen(true)
        setResultState('danger')
      })
    }
    toggle()
  }

  useEffect(() => {
    if (isToastOpen) {
      // Auto-dismiss the toast after 3 seconds
      const timer = setTimeout(() => setIsToastOpen(false), 3000)
      return () => clearTimeout(timer)
    }
  }, [isToastOpen])

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleLongitudeChange = (e) => {
    setLongitude(e.target.value)
  }

  const handleLatitudeChange = (e) => {
    setLatitude(e.target.value)
  }

  const handleUrlChange = (e) => {
    setUrl(e.target.value)
  }

  useEffect(() => {
    if (name && description && latitude && longitude && url) {
      setIsPlaceValid(true)
    } else {
      setIsPlaceValid(false)
    }
    if (isNaN(parseFloat(latitude)) || isNaN(parseFloat(longitude))) {
      setIsPlaceValid(false)
    }
  }, [name, description, latitude, longitude, url])

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop='static'
      >
        <ModalHeader toggle={toggle}>
          Create New Place
        </ModalHeader>
        <ModalBody>
          <TextInput icon='/media/geo-alt-fill.svg' value={name} type='text' label='place name' dataType='string' handleChange={handleNameChange} />
          <TextInput icon='/media/body-text.svg' value={description} type='textarea' label='place description' dataType='string' handleChange={handleDescriptionChange} />
          <TextInput icon='/media/at.svg' value={longitude} type='text' label='longitude' dataType='number' handleChange={handleLongitudeChange} />
          <TextInput icon='/media/at.svg' value={latitude} type='text' label='latitude' dataType='number' handleChange={handleLatitudeChange} />
          <TextInput icon='/media/link.svg' value={url} type='text' label='url' dataType='string' handleChange={handleUrlChange} />
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={handleSubmit} disabled={!isPlaceValid}>
            Create
          </Button>{' '}
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Collapse horizontal isOpen={isToastOpen}>
        <div className='position-fixed bottom-0 end-0'>
          <Toast>
            <ToastHeader icon={resultState} toggle={() => setIsToastOpen(false)}>
              Message
            </ToastHeader>
            <ToastBody>
              {message}
            </ToastBody>
          </Toast>
        </div>
      </Collapse>
    </div>
  )
}

AddOnePlaceModal.propTypes = propTypes

export default AddOnePlaceModal
