import React, { useState, useRef, useEffect } from 'react'
import { loginSuccess, loginError } from '../utils/userAction'
import { Helmet } from 'react-helmet'
import { Container, Row, Col, Button, Input, FormGroup } from 'reactstrap'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import ShowButton from '../components/show-button'
import { post } from '../utils/httpAgent'
import Alert from '../shared/alert'
import { getParameterByName } from '../utils/common'

const Login = ({ authenticated, loginSuccess, loginError }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)
  const [hasError, setHasError] = useState({})
  const [, setHelp] = useState({})
  const [, setRole] = useState('')
  const [returnUrl, setReturnUrl] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const usernameRef = useRef(null)

  useEffect(() => {
    if (usernameRef.current) {
      usernameRef.current.focus()
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLoading(true)

    post('/1/login', {
      username,
      password
    }).then(r => {
      if (r.success === true) {
        localStorage.setItem('token', r.data.token)
        delete r.data.token
        setError('')
        setLoading(false)
        setRole(r.data.role)
        setReturnUrl(getParameterByName('returnUrl'))
        loginSuccess(r.data)
        setTimeout(() => {
          setSuccess(true)
        }, 600)
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        setLoading(false)
        setError(state.error)
        setHasError(state.hasError)
        setHelp(state.help)
        loginError()
      }
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  const handleShowPassword = (e) => {
    e.preventDefault()
    setShowPassword(prevState => !prevState)
  }

  if (success && returnUrl) {
    return (<Redirect to={returnUrl} />)
  } else if (authenticated) {
    return <Redirect to='/earth' />
  }

  return (
    <div className='auth-bg'>
      <Container className='login-container'>
        <Helmet>
          <title>Sign in</title>
        </Helmet>

        <Row>
          <Col sm={12} className='login-col'>
            {error &&
              <Alert
                type='danger'
                message={error}
              />}
            <FormGroup>
              <Input
                innerRef={usernameRef}
                name='username'
                placeholder='ID'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                invalid={hasError.username}
              />
            </FormGroup>
            <FormGroup className='mb-20px position-relative'>
              <Input
                type={showPassword ? 'text' : 'password'}
                name='password'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                invalid={hasError.password}
              />
              {!hasError.password &&
                <ShowButton
                  show={showPassword}
                  onClick={handleShowPassword}
                />}
            </FormGroup>
            <Button
              color='success'
              outline
              onClick={handleSubmit}
              disabled={loading}
            >
              Sign in
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = state => ({
  authenticated: state.index.authenticated
})

const mapDispatchToProps = dispatch => ({
  loginSuccess (user) {
    dispatch(loginSuccess(user))
  },
  loginError () {
    dispatch(loginError())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
