import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, FormFeedback, Row, Col } from 'reactstrap'
import { useEffect, useState } from 'react'
import FileEditComponent from './file-edit-component'
import { post } from '../utils/httpAgent'
import { useDispatch } from 'react-redux'

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func
}

const AddPlacesModal = ({ modal, toggle }) => {
  const [file, setFile] = useState(null)
  const [validFile, setValidFile] = useState(true)
  const [placeData, setPlaceData] = useState(null)
  const [isPlaceValid, setIsPlaceValid] = useState(false)
  const dispatch = useDispatch()

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleEditPlaces = (editedPlace, key) => {
    setPlaceData(placeData.map((place, index) => {
      if (index === key) return editedPlace
      else return place
    }))
  }

  useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        try {
          const jsonData = JSON.parse(event.target.result)
          if (jsonData.places === undefined) {
            setValidFile(false)
            handleIsPlaceValid(false)
            setPlaceData([])
          } else {
            setValidFile(true)
            setPlaceData(jsonData.places)
          }
        } catch (error) {
          setValidFile(false)
          handleIsPlaceValid(false)
        }
      }
      reader.readAsText(file)
    }
  }, [file])

  const handleSubmit = () => {
    post('/1/account/place/addfile', { places: placeData }).then(response => {
      if (response.success) {
        dispatch({ type: 'ADD_PLACE_BY_FILE', places: response.data.places })
      }
    }).catch(err => {
      console.error(err)
    })
    toggle()
  }

  // Function to handle the download
  const downloadJsonFile = () => {
    const jsonData = {
      places: [
        {
          name: 'Place 1',
          description: 'Description for Place 1',
          longitude: 120.9999,
          latitude: 24.8888,
          webSrc: 'https://example.com/place1'
        },
        {
          name: 'Place 2',
          description: 'Description for Place 2',
          longitude: 121.1111,
          latitude: 25.2222,
          webSrc: 'https://example.com/place2'
        }
        // you can decide the number of places
      ]
    }
    const fileData = JSON.stringify(jsonData, null, 2)
    const blob = new Blob([fileData], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'example.json'
    link.click()

    URL.revokeObjectURL(url)
  }

  const handleIsPlaceValid = (flag = false) => {
    setIsPlaceValid(flag)
  }

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop='static'
      >
        <ModalHeader toggle={toggle}>
          Select a JSON file to upload
        </ModalHeader>
        <ModalBody className='card-content' style={{ height: 700 }}>
          <FormGroup>
            <Row>
              <Col className='ps-3 pt-2' md='9'>
                JSON File
              </Col>
              <Col md='3'>
                <Button color='link' onClick={downloadJsonFile}>
                  examples
                </Button>
              </Col>
            </Row>
            <Input
              id='exampleFile'
              name='file'
              type='file'
              onChange={handleFileChange}
              accept='.json'
              invalid={!validFile}
            />
            <FormFeedback>
              Not a valid JSON file.
            </FormFeedback>
          </FormGroup>
          {placeData && (
            <div>
              {placeData.map((place, index) => (
                <FileEditComponent key={index} index={index} place={place} handleEditPlaces={handleEditPlaces} handleIsPlaceValid={handleIsPlaceValid} />
              ))}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={handleSubmit} disabled={!isPlaceValid}>
            Create
          </Button>{' '}
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

AddPlacesModal.propTypes = propTypes

export default AddPlacesModal
