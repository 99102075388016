import { useState } from 'react'
import { Entity, BillboardGraphics } from 'resium'
import { Cartesian3, VerticalOrigin } from 'cesium'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const propTypes = {
  longtitude: PropTypes.number,
  latitude: PropTypes.number,
  id: PropTypes.string,
  show: PropTypes.bool,
  cameraDistance: PropTypes.number,
  handleOpenInfrom: PropTypes.func
}

const EntityComponent = ({
  id = '',
  show = false,
  handleOpenInfrom = () => { }
}) => {
  const [iconHoverSize, setIconHoverSize] = useState(0.4)
  const clampedPosition = useSelector((state) => {
    return state.clampPositions.find(pos => pos.id === id)
  })
  return (
    <Entity
      position={clampedPosition ? new Cartesian3(clampedPosition.position[0], clampedPosition.position[1], clampedPosition.position[2]) : null}
      id={id}
      show={show}
      onMouseEnter={() => setIconHoverSize(0.5)}
      onMouseLeave={() => setIconHoverSize(0.4)}
      onClick={handleOpenInfrom}
    >
      {/* <PolylineGraphics
        positions={new CallbackProperty(() => [
          Cartesian3.fromDegrees(longtitude, latitude, 0),
          Cartesian3.fromDegrees(longtitude, latitude, cameraDistance / 10)
        ], false)}
        width={1}
        material={Color.WHITE}
      /> */}
      <BillboardGraphics
        image='media/icon_forearth.png'
        scale={iconHoverSize}
        verticalOrigin={VerticalOrigin.BOTTOM}
        disableDepthTestDistance={Number.POSITIVE_INFINITY}
      />
    </Entity>
  )
}

EntityComponent.propTypes = propTypes

export default EntityComponent
