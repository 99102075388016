import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import AddOnePlaceModal from './add-one-place-modal'
import AddFileModal from './add-file-modal'
import { useState } from 'react'

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func
}

const AddPlacesModal = ({ modal, toggle }) => {
  const [isOnePlaceModal, setIsOnePlaceModal] = useState(false)
  const [isFileModal, setIsFileModal] = useState(false)
  const handleOnePlaceModal = () => {
    setIsOnePlaceModal(!isOnePlaceModal)
    setIsFileModal(false)
    if (isOnePlaceModal) {
      toggle()
    }
  }
  const handleFileModal = () => {
    setIsFileModal(!isFileModal)
    setIsOnePlaceModal(false)
    if (isFileModal) {
      toggle()
    }
  }
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>
          Choose How You Want to Add Places
        </ModalHeader>
        <ModalBody className='d-flex justify-content-center align-items-center'>
          <Button className='m-2 add-places-button' onClick={handleOnePlaceModal}>
            <img className='pt-3' src='/media/pencil-square.svg' alt='add' style={{ width: '137px' }} />
            <p className='pt-1' style={{ color: 'black' }}>Create by input</p>
          </Button>{' '}
          <Button className='m-2 add-places-button' onClick={handleFileModal}>
            <img className='pt-3' src='/media/filetype-json.svg' alt='add' style={{ width: '125px' }} />
            <p className='pt-3' style={{ color: 'black' }}>Create by JSON file</p>
          </Button>
        </ModalBody>
        <AddOnePlaceModal modal={isOnePlaceModal} toggle={handleOnePlaceModal} />
        <AddFileModal modal={isFileModal} toggle={handleFileModal} />
      </Modal>
    </div>
  )
}

AddPlacesModal.propTypes = propTypes

export default AddPlacesModal
