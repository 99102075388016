import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardTitle, CardBody, CardText, Col, Row } from 'reactstrap'
import { get } from '../utils/httpAgent'

const propTypes = {
  id: PropTypes.string,
  positionX: PropTypes.number,
  positionY: PropTypes.number,
  title: PropTypes.string,
  handleOpen: PropTypes.func
}

const EmbeddedWebPage = ({
  id = '',
  positionX = 0,
  positionY = 0,
  title = '',
  handleOpen = () => { }
}) => {
  const [webStyle, setWebStyle] = useState({
    position: 'absolute',
    width: '800px',
    top: '50px',
    marginLeft: '1070px'
  })
  const [imgBoxStyle, setImgBoxStyle] = useState()
  const [showMediumEntity, setShowMediumEntity] = useState(false)
  const [webWidth, setWebWidth] = useState(780)
  const [webHeight, setWebHeight] = useState(780 * 9 / 16)
  const [description, setDescription] = useState('')
  const [webSrc, setWebSrc] = useState('')
  const paddingHorizontal = 20
  const paddingLayoutHorzontal = 50

  const handleEmbeddedWebSize = useCallback(() => {
    if (showMediumEntity) {
      const newWebStyle = {
        width: '560px',
        height: '420px',
        position: 'absolute',
        border: 'none',
        left: `${positionX - 280}px`,
        top: `${positionY - 335}px`,
        zIndex: 1,
        scale: `${800 / 1500}`
      }
      const newImgBoxStyle = {
        width: '560px',
        height: '450px',
        position: 'absolute',
        left: `${positionX - 280}px`,
        top: `${positionY - 345}px`,
        zIndex: 1,
        scale: `${800 / 1500}`
      }
      setImgBoxStyle(newImgBoxStyle)
      setWebStyle(newWebStyle)
      setWebWidth(500)
      setWebHeight(500 * 9 / 16)
    } else {
      const innerWidth = window.innerWidth
      const tmpWidth = innerWidth * 0.4
      const posX = innerWidth - tmpWidth - paddingLayoutHorzontal
      const newWebStyle = {
        position: 'absolute',
        width: `${tmpWidth + paddingHorizontal}px`,
        height: 'auto',
        top: '50px',
        left: `${posX}px`
      }
      setWebStyle(newWebStyle)
      setWebWidth(tmpWidth)
      setWebHeight(tmpWidth * 9 / 16)
    }
  }, [showMediumEntity, positionX, positionY])

  useEffect(() => {
    handleEmbeddedWebSize()
  }, [handleEmbeddedWebSize])

  useEffect(() => {
    get(`/1/account/places/placeid?id=${id}`).then((response) => {
      if (response.success) {
        const place = response.data.place
        if (place) {
          setDescription(place.description)
          setWebSrc(place.webSrc)
        }
      }
    })
  }, [id])

  const handleShowMediumEntity = () => {
    setShowMediumEntity(!showMediumEntity)
  }
  return (
    <div id={id}>
      {showMediumEntity && <img src='/media/img_box.png' style={imgBoxStyle} alt='info' />}
      <Card style={webStyle}>
        <CardBody>
          <Row>
            <Col md='9'>
              <CardTitle tag='h3'>{title}</CardTitle>
            </Col>
            <Col md='3' className='d-flex  justify-content-end'>
              {showMediumEntity &&
                <div className='d-flex justify-content-center align-items-center img-button'>
                  <img src='/media/box-arrow-in-up-right.svg' alt='maximize button' onClick={handleShowMediumEntity} />
                </div>}
              {!showMediumEntity &&
                <div className='d-flex justify-content-center align-items-center img-button'>
                  <img src='/media/box-arrow-in-down-left.svg' alt='maximize button' onClick={handleShowMediumEntity} />
                </div>}
              <div className='d-flex justify-content-center align-items-center img-button'>
                <img src='/media/x.svg' alt='close button' onClick={handleOpen} />
              </div>
            </Col>
          </Row>
          {!showMediumEntity && <CardText>{description}</CardText>}
          <Row className='mt-3 d-flex justify-content-center'>
            <iframe
              width={webWidth}
              height={webHeight}
              src={`${webSrc}${webSrc.includes('?') ? '&' : '?'}autoplay=1`}
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              title='Embedded youtube'
            />
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}

EmbeddedWebPage.propTypes = propTypes

export default EmbeddedWebPage
