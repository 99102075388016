import { configureStore } from '@reduxjs/toolkit'

const initialState = {
  index: {
    authenticated: false,
    user: '',
    email: '',
    role: ''
  },
  places: [],
  clampPositions: []
}

// Reducer
function rootReducer (state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS_USER':
      return {
        ...state,
        index: { // 首頁
          authenticated: true, // 是否驗證
          user: action.user.user, // 使用者名稱
          email: action.user.email,
          role: action.user.role // 使用者權限
        }
      }
    case 'LOGIN_ERROR_USER':
      return {
        ...state,
        index: { // 首頁
          authenticated: false, // 是否驗證
          user: '', // 使用者名稱
          email: '',
          role: '' // 使用者權限
        }
      }
    case 'GET_PLACES':
      return {
        ...state,
        places: action.places
      }
    case 'ADD_PLACE':
      return {
        ...state,
        places: [action.place, ...state.places]
      }
    case 'ADD_PLACE_BY_FILE':
      return {
        ...state,
        places: [...action.places, ...state.places]
      }
    case 'UPDATE_PLACE':
      return {
        ...state,
        places: state.places.map(place => {
          if (place.id === action.place.id) {
            return action.place
          }
          return place
        }).sort((a, b) => a.priority - b.priority)
      }
    case 'DELETE_PLACE':
      return {
        ...state,
        places: state.places.filter(place => place.id !== action.place.id)
      }
    case 'ADD_CLAMPED_POSITIONS':
      return {
        ...state,
        clampPositions: action.clampPositions
      }
    case 'DELETE_CLAMPED_POSITION':
      return {
        ...state,
        clampPositions: state.clampPositions.filter(position => position.id !== action.id)
      }
    default:
      return state
  }
}

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState
})

export default store
