import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { post, get } from '../utils/httpAgent'

import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'

import PlaceButton from './place-button'
import { useEffect } from 'react'

const propTypes = {
  onClick: PropTypes.func
}

const PlacesContainer = ({ onClick, style }) => {
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor), useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }))
  const places = useSelector(state => state.places)
  const dispatch = useDispatch()

  const handleDragEnd = event => {
    const { active, over } = event

    if (active.id !== over.id) {
      const srcIdx = places.findIndex(p => p.id === active.id)
      const dstIdx = places.findIndex(p => p.id === over.id)

      const newPlace = { ...places[srcIdx] }
      if (dstIdx === 0) {
        newPlace.priority = places[0].priority - 1
      } else if (dstIdx === places.length - 1) {
        newPlace.priority = places[places.length - 1].priority + 1
      } else if (srcIdx < dstIdx) {
        newPlace.priority = (places[dstIdx].priority + places[dstIdx + 1].priority) / 2
      } else {
        newPlace.priority = (places[dstIdx - 1].priority + places[dstIdx].priority) / 2
      }

      dispatch({ type: 'UPDATE_PLACE', place: newPlace })

      post('/1/account/place/update', newPlace).then(response => {
        console.log('update', response.data.place)
      }).catch(err => {
        console.error(err)
      })
    }
  }

  useEffect(() => {
    console.log('places update', places)
  }, [places])

  if (!places?.length) return null

  return (
    <div className='card-content' style={style}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}
      >
        <SortableContext
          items={places}
          strategy={verticalListSortingStrategy}
          handle
        >
          {places.map(place => <PlaceButton key={place.id} id={place.id} placeName={place.name} longitude={place.longitude} latitude={place.latitude} handleClick={onClick} />)}
          <DragOverlay />
        </SortableContext>
      </DndContext>
    </div>
  )
}

PlacesContainer.propTypes = propTypes

export default PlacesContainer
