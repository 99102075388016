import { Form, FormGroup, Label, Input, InputGroup, InputGroupText, FormFeedback } from 'reactstrap'
import { useState, useRef } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  dataType: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func
}
const TextInput = ({ type = 'text', value = '', icon = '', label = '', dataType = 'string', handleChange = () => {} }) => {
  const [invalid, setInvalid] = useState(false)
  const [message, setMessage] = useState('')
  const ref = useRef(null)
  const title = label.toUpperCase()
  const handleInputChange = (e) => {
    handleChange(e)
    ref.current.value = e.target.value
    if (e.target.value === '') {
      ref.current.value = null
      setMessage('Input is required.')
      setInvalid(true)
    } else if (invalid) {
      setInvalid(false)
    }
    if (dataType === 'number') {
      if (isNaN(e.target.value)) {
        setInvalid(true)
        setMessage('Input must be a number.')
      }
    }
  }

  return (
    <div>
      <Form className='p-1'>
        <FormGroup>
          <Label for='exampleEmail'>{title}</Label>
          <InputGroup>
            <InputGroupText>
              <img src={icon} alt='search icon' />
            </InputGroupText>
            <Input ref={ref} value={value} invalid={invalid} placeholder={label} multiple type={type} onChange={handleInputChange} />
            <FormFeedback invalid={invalid.toString()}>
              {message}
            </FormFeedback>
          </InputGroup>
        </FormGroup>
      </Form>
    </div>
  )
}

TextInput.propTypes = propTypes

export default TextInput
